import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS, EXTERNAL_URLS } from '../../config/urls'

import landingStyles from '../Landing/Landing.module.scss'
import styles from './Services.module.scss'

import BackgroundImage_2 from '../images/bg-homepage-2.png'

import PrenatalIconImage from '../images/prenatal-icon@2x.png'
import PostnatalIconImage from '../images/postnatal-icon@2x.png'
import GynecologyIconImage from '../images/gynecology-icon@2x.png'
import MenopauseIconImage from '../images/menopause-icon@2x.png'
import ArrowRight from '../svg/ArrowRight'

export class Services extends React.Component {
  renderTopServices = () => {
    return (
      <div className={styles.servicesSection}>
        <img
          className={styles.servicesBackground}
          src={BackgroundImage_2}
          alt="VGA background 2"
        />

        <div className={styles.servicesPanes}>
          <div className={styles.servicesPane}>
            <div className={styles.servicesPaneContent}>
              <img
                className={styles.servicesIcon}
                src={GynecologyIconImage}
                alt="Gynecology - Valencia Gynecology Associates"
              />
              <div className={styles.servicesName}>Gynecology</div>
              <div className={styles.servicesDesc}>
                We provide preventative, diagnostic and specialized gynecologic services for women.
              </div>
              <div>
                <Link
                  href={INTERNAL_URLS.SERVICES_GYNECOLOGY}
                  className={styles.servicesLink}
                >
                  Learn More
                  <ArrowRight className={styles.servicesLinkArrow} />
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.servicesPane}>
            <div className={styles.servicesPaneContent}>
              <img
                className={styles.servicesIcon}
                src={PrenatalIconImage}
                alt="Prenatal - Valencia Gynecology Associates"
              />
              <div className={styles.servicesName}>
                Prenatal Care & Obstetrics
              </div>
              <div className={styles.servicesDesc}>
                Our obstetrical services include pre-pregnancy, pregnancy and post-pregnancy care plans.
              </div>
              <div>
                <Link
                  to={INTERNAL_URLS.SERVICES_PRENATAL}
                  className={styles.servicesLink}
                >
                  Learn More
                  <ArrowRight className={styles.servicesLinkArrow} />
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.servicesPane}>
            <div className={styles.servicesPaneContent}>
              <img
                className={styles.servicesIcon}
                src={PostnatalIconImage}
                alt="Postnatal - Valencia Gynecology Associates"
              />
              <div className={styles.servicesName}>Postnatal Care</div>
              <div className={styles.servicesDesc}>
                We will work to make sure that you and your new baby remain as healthy as possible.
              </div>
              <div>
                <Link
                  href={INTERNAL_URLS.SERVICES_POSTNATAL}
                  className={styles.servicesLink}
                >
                  Learn More
                  <ArrowRight className={styles.servicesLinkArrow} />
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.servicesPane}>
            <div className={styles.servicesPaneContent}>
              <img
                className={styles.servicesIcon}
                src={MenopauseIconImage}
                alt="Menopause - Valencia Gynecology Associates"
              />
              <div className={styles.servicesName}>Menopause & Beyond</div>
              <div className={styles.servicesDesc}>
                We offer personalized care as your body transitions through the phases of menopause.
              </div>
              <div>
                <Link
                  href={INTERNAL_URLS.SERVICES_MENOPAUSE}
                  className={styles.servicesLink}
                >
                  Learn More
                  <ArrowRight className={styles.servicesLinkArrow} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderOtherServices = () => {
    return (
      <div className={styles.servicesOther}>
        <div className={styles.servicesOtherTitle}>Available Services</div>
        <div className={styles.servicesOtherContent}>
          <div className={styles.servicesOtherItem}>Annual Exams</div>
          <div className={styles.servicesOtherItem}>Full Obstetrical Care</div>
          <div className={styles.servicesOtherItem}>Well Women Exams</div>
          <div className={styles.servicesOtherItem}>Gynecological Surgery</div>
          <div className={styles.servicesOtherItem}>
            In-office Endometrial & Colpo Biopsy, Cryotherapy
          </div>
          <div className={styles.servicesOtherItem}>Birth Control</div>
          <div className={styles.servicesOtherItem}>
            Contraception Management
          </div>
          <div className={styles.servicesOtherItem}>STD Testing</div>
          <div className={styles.servicesOtherItem}>BRCA & COLARIS Testing</div>
          <div className={styles.servicesOtherItem}>
            HPV (Human Papilloma Virus)
          </div>
          <div className={styles.servicesOtherItem}>Ultrasound</div>
          <div className={styles.servicesOtherItem}>
            Breast, Gynecological & Menopausal Issues
          </div>
          <div className={styles.servicesOtherItem}>
            In-office Pelvic, Obstetrical Ultrasounds & Bone Densities
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className={styles.page}>
          <div className={styles.pageContent}>
            <div className={styles.pageHeaderSection}>
              <div className={styles.pageHeader}>
                <div className={landingStyles.aboutSectionBorder} />
                <h2 className={styles.headerTitle}>Our Services</h2>
                <div className={styles.headerSubtitle}>
                  We're here to provide a variety of services to our patients,
                  ensuring the best and most comprehensive care. Here are the
                  specializations of our practice and the full list of services
                  below.
                </div>
              </div>
            </div>
            {this.renderTopServices()}
            {this.renderOtherServices()}
          </div>
        </div>
      </div>
    )
  }
}
