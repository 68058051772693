import React from 'react'

import Layout from '../components/layout'
import { Services } from '../components/Services'

export const ServicesPage = () => (
  <Layout seo={{ title: 'Services' }}>
    <Services />
  </Layout>
)

export default ServicesPage
